import type { NextPage } from 'next'


const Home: NextPage = () => {
  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      In construction
      <a style={{}}href="http://blog.tofu4956.net" rel="noopener noreferrer">ブログ</a>
      <a href="http://twitter.com/tofu4956" rel="noopener noreferrer">Twitter</a>
    </div>
  )
}

export default Home
